function BuyCryptoIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 17">
      <g id="BytesizeCart" transform="translate(-1.5 -1.5)">
        <path id="Path_3810" data-name="Path 3810" d="M4.429,4.1H19L17.179,10.9H6.25M17.179,13H6.857L3.821,2H2" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <circle id="Ellipse_522" data-name="Ellipse 522" cx="1.5" cy="1.5" r="1.5" transform="translate(13 15)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <circle id="Ellipse_523" data-name="Ellipse 523" cx="1.5" cy="1.5" r="1.5" transform="translate(6.333 15)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <text id="_" data-name="$" transform="translate(9 4)" fill="#e7e9ec" fontSize="5" fontFamily="IBMPlexSans, IBM Plex Sans"><tspan x="-1" y="5">$</tspan></text>
      </g>
    </svg>
  );
}

export default BuyCryptoIcon;
