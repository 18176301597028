function PhoneIcon() {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.90527 8.74992C4.90527 5.72507 4.90527 4.21265 5.84498 3.27295C6.78467 2.33325 8.2971 2.33325 11.3219 2.33325C14.3468 2.33325 15.8592 2.33325 16.7989 3.27295C17.7386 4.21265 17.7386 5.72507 17.7386 8.74992V14.2499C17.7386 17.2747 17.7386 18.7871 16.7989 19.7269C15.8592 20.6666 14.3468 20.6666 11.3219 20.6666C8.2971 20.6666 6.78467 20.6666 5.84498 19.7269C4.90527 18.7871 4.90527 17.2747 4.90527 14.2499V8.74992Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M10.4053 17.9167H12.2386" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.5719 2.33325L8.65348 2.82277C8.83029 3.8836 8.91869 4.41402 9.28249 4.73679C9.662 5.07348 10.2 5.08325 11.3219 5.08325C12.4438 5.08325 12.9818 5.07348 13.3613 4.73679C13.7251 4.41402 13.8135 3.8836 13.9903 2.82277L14.0719 2.33325" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
  );
}

export default PhoneIcon;
