import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Tooltip.module.scss';

function Tooltip({
  children, anchor, className, containerClassnaame, show, special, ...props
}) {
  let positionClass;
  if (show === 'center') {
    positionClass = styles.showCenter;
  } else if (show === 'right') {
    positionClass = styles.showRight;
  } else {
    positionClass = styles.showLeft;
  }

  const containerClassName = classnames(
    positionClass,
    styles.container,
    { [containerClassnaame]: !!containerClassnaame },
  );

  const tooltipClassName = classnames(
    special === 'newQuizPool' ? styles.special : styles.tooltip,
    { [className]: !!className },
  );

  return (
    <div className={containerClassName} {...props}>
      {anchor}
      <div className={tooltipClassName}>{children}</div>
    </div>
  );
}

Tooltip.propTypes = {
  anchor: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  show: PropTypes.string,
  special: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  containerClassnaame: PropTypes.string,
};

Tooltip.defaultProps = {
  anchor: '',
  show: '',
  special: '',
  children: '',
  className: '',
  containerClassnaame: '',
};

export default Tooltip;
