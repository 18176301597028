import QrcodeA from '@/components/common/Icons/downloadQrcode/a.png';
import QrcodeB from '@/components/common/Icons/downloadQrcode/b.png';
import QrcodeC from '@/components/common/Icons/downloadQrcode/c.png';
import QrcodeD from '@/components/common/Icons/downloadQrcode/d.png';
import QrcodeE from '@/components/common/Icons/downloadQrcode/e.png';
import QrcodeG from '@/components/common/Icons/downloadQrcode/g.png';
import QrCodeEN from '@/components/common/Icons/downloadQrcode/QRcode-en-US.png';
import QrCodeCN from '@/components/common/Icons/downloadQrcode/QRcode-zh-CN.png';

const QRcodes = {
  en: QrCodeEN,
  zh: QrCodeCN,
  a: QrcodeA,
  b: QrcodeB,
  c: QrcodeC,
  d: QrcodeD,
  e: QrcodeE,
  g: QrcodeG,
};

const qrcodeList = {
  JsHgJj: 'https://app.bs.inokn.com?t=bitop&b=a',
  MYVU3n: 'https://app.bs.inokn.com?t=bitop&b=b',
  '6jPpWd': 'https://app.bs.inokn.com?t=bitop&b=c',
  Ic5QiJ: 'https://app.bs.inokn.com?t=bitop&b=d',
  rEyIQq: 'https://app.bs.inokn.com?t=bitop&b=e',
  Qiy8p8: 'https://app.bs.inokn.com?t=bitop&b=g',
};

const useQRcode = ({ locale, ru }) => {
  const url = qrcodeList[ru];
  if (url) {
    return { qrCode: QRcodes[url.slice(-1)], url };
  }
  return { qrCode: QRcodes[locale] };
};

export default useQRcode;
