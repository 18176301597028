import useSWR from 'swr';
import { useTranslation } from 'next-i18next';

import { mergeDeep } from '../functions/common';
import restful from '../api/restful';

export default function useRequest(request, { fallbackData, ...config } = {}) {
  const { i18n } = useTranslation();
  const defaultRequest = {
    method: 'get',
    headers: {
      'Accept-Language': i18n.language === 'zh' ? 'zh-CN' : 'en-US',
    },
  };
  const combinedRequest = mergeDeep(defaultRequest, request);
  const {
    data, error, isValidating, mutate,
  } = useSWR(
    combinedRequest && JSON.stringify(combinedRequest),
    () => restful.REQUEST(combinedRequest || {}).then((response) => {
      if (response.data.code === 403) {
        const err = new Error('Frequent request error');
        err.error = response.data;
        throw err;
      }

      return response.data;
    }),
    {
      dedupingInterval: 1000,
      errorRetryInterval: 2000,
      ...config,
      headers: { 'Accept-Language': 'zh-CN' },
      fallbackData: fallbackData && {
        status: 200,
        statusText: 'InitialData',
        headers: {},
        data: fallbackData,
      },
    },
  );
  return {
    data, error, isLoading: isValidating, mutate,
  };
}
