function MdIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title />
      <g id="Icons-/-Systems-/-Media-/-Medium-Hover" stroke="none" strokeWidth="1" fillRule="evenodd">
        <path fill="currentColor" d="M9.618 0.57a87.731 87.731 0 0 0 -0.615 0.082c-1.784 0.238 -3.515 0.988 -5.045 2.182 -0.451 0.353 -1.444 1.378 -1.813 1.874C1.079 6.136 0.41 7.735 0.139 9.507c-0.107 0.697 -0.107 2.297 0 2.994 0.345 2.239 1.3 4.175 2.855 5.779 1.612 1.665 3.683 2.732 5.988 3.08 0.664 0.102 1.956 0.127 2.637 0.045 2.371 -0.275 4.422 -1.23 6.091 -2.834 1.731 -1.661 2.785 -3.696 3.154 -6.091 0.102 -0.673 0.102 -2.285 -0.004 -2.974 -0.271 -1.776 -0.952 -3.396 -2.043 -4.848 -0.34 -0.455 -1.345 -1.485 -1.776 -1.825 -1.513 -1.185 -3.158 -1.903 -5.024 -2.19 -0.41 -0.062 -2.104 -0.115 -2.399 -0.074zm-1.842 5.865c0.439 0.078 0.833 0.213 1.276 0.443 1.395 0.726 2.309 2.096 2.473 3.704 0.053 0.521 0 1.099 -0.144 1.653 -0.459 1.706 -1.878 3.015 -3.626 3.347 -0.463 0.09 -1.272 0.086 -1.706 -0.004 -0.931 -0.197 -1.731 -0.619 -2.379 -1.272 -2.133 -2.133 -1.714 -5.693 0.861 -7.284 0.952 -0.587 2.084 -0.792 3.244 -0.587zm7.133 0.34c0.787 0.39 1.44 1.641 1.645 3.162 0.074 0.554 0.074 1.579 0 2.133 -0.205 1.53 -0.853 2.773 -1.657 3.166 -0.234 0.115 -0.316 0.135 -0.583 0.135s-0.349 -0.021 -0.582 -0.135c-0.747 -0.369 -1.353 -1.448 -1.62 -2.879 -0.102 -0.554 -0.102 -2.153 0 -2.707 0.119 -0.644 0.266 -1.099 0.517 -1.62 0.587 -1.202 1.436 -1.669 2.28 -1.255zm3.15 0.406c0.234 0.242 0.418 0.906 0.55 1.977 0.102 0.837 0.102 2.855 0 3.691 -0.135 1.095 -0.324 1.76 -0.566 1.993 -0.115 0.111 -0.127 0.115 -0.234 0.057 -0.287 -0.164 -0.509 -0.906 -0.648 -2.186 -0.082 -0.755 -0.094 -2.518 -0.021 -3.269 0.123 -1.251 0.361 -2.129 0.627 -2.313 0.127 -0.09 0.164 -0.082 0.291 0.049z" id="Path" fillRule="nonzero" />
      </g>
    </svg>
  );
}

export default MdIcon;
