/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Button.module.scss';

function Button({
  children, onClick, type, className, disabled, variant, ...restProps
}) {
  return (
    <button
      className={classnames(styles[variant], { [className]: !!className })}
      onClick={onClick}
      type={type}
      disabled={disabled}
      {...restProps}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'plane', 'empty']),
};

Button.defaultProps = {
  children: '',
  onClick: () => null,
  type: 'button',
  className: '',
  disabled: false,
  variant: 'default',
};

export default Button;
