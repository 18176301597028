import { useMemo } from 'react';
import useRequest from '../useRequest';

const requestConfig = {
  useAssetList: '/api/user/asset/list',
  usePoolInfo: '/api/quizPool/v2/poolInfo',
  usePrizeList: '/api/quizPool/v2/prize/list',
  useDrawnBoxList: '/api/quizPool/v2/box/list/1',
  useBoxList: '/api/quizPool/v2/box/page',
};

export function useAssetList() {
  const { data, error, mutate } = useRequest({ url: requestConfig.useAssetList });
  const result = useMemo(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return { done: true, error };
    }
    if (data) {
      return {
        assetList: data,
        mutateAL: mutate,
      };
    }
    return {};
  }, [data, error]);
  return result;
}

export function usePoolInfo() {
  const { data, error, mutate } = useRequest({ url: requestConfig.usePoolInfo });
  const result = useMemo(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return { done: true, error };
    }
    if (data) {
      return {
        poolInfo: data.data,
        mutatePI: mutate,
      };
    }
    return {};
  }, [data, error]);
  return result;
}

export function usePrizeList({ quizPoolCode }) {
  const { data, error } = useRequest({
    url: `${requestConfig.usePrizeList}?quizPoolCode=${quizPoolCode}`,
  });
  const result = useMemo(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return { done: true, error };
    }
    if (data) {
      return { data };
    }
    return {};
  }, [data, error]);
  return result;
}

export function useDrawnBoxList({ quizPoolCode }) {
  const { data, error, mutate } = useRequest({
    url: `${requestConfig.useDrawnBoxList}?quizPoolCode=${quizPoolCode}`,
  });
  const result = useMemo(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return { done: true, error };
    }
    if (data) {
      return {
        dataDBL: data.data,
        totalDBL: data.data?.length,
        mutateDBL: mutate,
      };
    }
    return {};
  }, [data, error]);
  return result;
}

export function useBoxList({ quizPoolCode, page, rows }) {
  const { data, error, mutate } = useRequest({
    url: `${requestConfig.useBoxList}?quizPoolCode=${quizPoolCode}&page=${page}&rows=${rows}`,
  });
  const result = useMemo(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return { done: true, error };
    }
    if (data) {
      return {
        total: data.total,
        data: data.data,
        mutate,
      };
    }
    return {};
  }, [data, error]);
  return result;
}

export default {
  useAssetList,
  usePoolInfo,
  usePrizeList,
  useDrawnBoxList,
  useBoxList,
};
