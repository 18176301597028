function LanguageIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.99996 18.3334C14.6023 18.3334 18.3333 14.6025 18.3333 10.0001C18.3333 5.39771 14.6023 1.66675 9.99996 1.66675C5.39759 1.66675 1.66663 5.39771 1.66663 10.0001C1.66663 14.6025 5.39759 18.3334 9.99996 18.3334Z" stroke="#E2E2E3" strokeWidth="0.8" />
      <path d="M6.66663 10.0001C6.66663 15.0001 9.99996 18.3334 9.99996 18.3334C9.99996 18.3334 13.3333 15.0001 13.3333 10.0001C13.3333 5.00008 9.99996 1.66675 9.99996 1.66675C9.99996 1.66675 6.66663 5.00008 6.66663 10.0001Z" stroke="#E2E2E3" strokeWidth="0.8" strokeLinejoin="round" />
      <path d="M17.5 12.5H2.5" stroke="#E2E2E3" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.5 7.5H2.5" stroke="#E2E2E3" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default LanguageIcon;
