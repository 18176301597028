function FbIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 2084.000000 2084.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,2084.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path d="M9920 20820 c-1333 -71 -2554 -361 -3730 -885 -2855 -1272 -4991 -3759 -5815 -6770 -259 -946 -378 -1902 -362 -2910 8 -467 28 -753 83 -1185 161 -1266 567 -2515 1180 -3638 1018 -1860 2569 -3362 4459 -4317 621 -313 1278 -566 1930 -743 99 -27 192 -52 208 -56 l27 -6 0 3460 0 3460 -1072 2 -1073 3 0 1590 0 1590 1071 3 1072 2 5 928 c5 900 9 1030 43 1392 170 1832 900 3055 2205 3695 587 288 1283 457 2159 525 228 18 824 24 1060 11 571 -32 1204 -106 1630 -191 107 -22 209 -43 225 -48 l30 -8 3 -1449 2 -1448 -62 6 c-293 30 -1331 42 -1583 19 -656 -60 -1086 -223 -1390 -527 -229 -229 -378 -530 -459 -928 -64 -311 -76 -528 -76 -1329 l0 -648 1700 0 c935 0 1700 -4 1700 -8 0 -5 -130 -719 -290 -1587 -159 -868 -290 -1582 -290 -1587 0 -4 -634 -8 -1410 -8 l-1410 0 0 -3575 c0 -2853 3 -3575 13 -3575 33 0 447 64 616 95 1630 299 3167 988 4491 2014 558 432 1139 991 1596 1537 413 491 805 1062 1128 1639 197 352 470 936 617 1323 706 1848 872 3861 478 5815 -457 2266 -1694 4356 -3469 5858 -1445 1222 -3164 2020 -5015 2329 -313 52 -664 92 -1040 120 -183 14 -987 21 -1185 10z" />
      </g>
    </svg>
  );
}

export default FbIcon;
