function ProfileIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="48px" height="64px" viewBox="0 0 48 64" version="1.1" className="injected-svg" data-src="/static/media/User.0fe1ccc0.svg">
      <title />
      <g id="Icons-/-Systems-/-Header-/-User" stroke="currentColor" strokeWidth="1" fillRule="evenodd">
        <path d="M24,23 C28.9705627,23 33,27.0294373 33,32 C33,34.4908289 31.9881385,36.7453248 30.3530454,38.3748581 L30.3234782,38.4111165 C28.69417,40.0109541 26.462587,41 24,41 C19.0294373,41 15,36.9705627 15,32 C15,27.0294373 19.0294373,23 24,23 Z M27.856,36.495 L20.675,36.495 L18.4193685,38.0080879 C19.8830045,39.3682126 21.8443651,40.2 24,40.2 C26.2056114,40.2 28.2078359,39.329197 29.6816245,37.9126398 L27.856,36.495 Z M24,23.8 C19.4712651,23.8 15.8,27.4712651 15.8,32 C15.8,34.0799588 16.5744108,35.9790442 17.8506322,37.4246562 L20.3311459,35.7629111 C20.3750579,35.7334888 20.4241389,35.713209 20.4754727,35.7029583 L20.5537986,35.6952148 L27.9939415,35.6952148 C28.0531819,35.6952148 28.1112979,35.7083656 28.1642532,35.7332836 L28.2393109,35.7793132 L30.2337028,37.3276133 C31.4596251,35.8945511 32.2,34.0337375 32.2,32 C32.2,27.4712651 28.5287349,23.8 24,23.8 Z M24,27 C25.9329966,27 27.5,28.5670034 27.5,30.5 C27.5,32.4329966 25.9329966,34 24,34 C22.0670034,34 20.5,32.4329966 20.5,30.5 C20.5,28.5670034 22.0670034,27 24,27 Z M24,27.8 C22.5088312,27.8 21.3,29.0088312 21.3,30.5 C21.3,31.9911688 22.5088312,33.2 24,33.2 C25.4911688,33.2 26.7,31.9911688 26.7,30.5 C26.7,29.0088312 25.4911688,27.8 24,27.8 Z" id="Combined-Shape" fillRule="nonzero" />
      </g>
    </svg>
  );
}

export default ProfileIcon;
