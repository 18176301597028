function AdvanceAuthIcon() {
  return (
    <svg
      width="10px"
      height="10px"
      viewBox="0 0 10 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Icons-/-Systems-/-User-/-High-Verify"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <polygon
          id="星形"
          fill="#FFD140"
          points="5 9.04622396 3.45491503 9.75528258 2.62168923 8.27346395 0.954915028 7.93892626 1.15181234 6.25035197 0 5 1.15181234 3.74964803 0.954915028 2.06107374 2.62168923 1.72653605 3.45491503 0.244717419 5 0.953776042 6.54508497 0.244717419 7.37831077 1.72653605 9.04508497 2.06107374 8.84818766 3.74964803 10 5 8.84818766 6.25035197 9.04508497 7.93892626 7.37831077 8.27346395 6.54508497 9.75528258"
        />
        <path
          d="M6.77683715,3.47764018 L4.28920896,5.8297765 L3.21661887,4.85040499 C3.01165203,4.663242 2.68624973,4.66670279 2.48577961,4.85674978 L2.4797317,4.86256721 C2.27964502,5.05848106 2.28539664,5.37043037 2.49243755,5.55947207 L3.90590702,6.85011721 C3.97207101,6.91071907 4.05410891,6.95356837 4.14358839,6.97426081 C4.32523502,7.03202309 4.53387942,6.99248765 4.67859367,6.85565448 L7.51400319,4.1746604 C7.71752341,3.98222382 7.71752341,3.67022508 7.51400319,3.47780498 C7.31030868,3.28520359 6.98035737,3.28520359 6.77683715,3.47764018 Z"
          id="路径"
          fill="#141416"
        />
      </g>
    </svg>
  );
}
export default AdvanceAuthIcon;
