function ThreadsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={21}
      height={21}
      viewBox="0 0 256 256"
      xmlSpace="preserve"
    >
      <g
        style={{
          stroke: 'none',
          strokeWidth: 0,
          strokeDasharray: 'none',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeMiterlimit: 10,
          fill: 'none',
          fillRule: 'nonzero',
          opacity: 1,
        }}
        transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
      >
        <path
          d="M 45 0 L 45 0 C 20.147 0 0 20.147 0 45 v 0 c 0 24.853 20.147 45 45 45 h 0 c 24.853 0 45 -20.147 45 -45 v 0 C 90 20.147 69.853 0 45 0 z M 28.122 64.404 c 3.797 4.827 9.637 7.303 17.359 7.361 c 6.961 -0.051 11.567 -1.707 15.399 -5.536 c 4.374 -4.37 4.292 -9.73 2.893 -12.992 c -0.823 -1.919 -2.312 -3.515 -4.322 -4.728 c -0.49 3.648 -1.598 6.544 -3.351 8.778 c -2.308 2.942 -5.617 4.551 -9.834 4.783 C 43.073 62.245 40 61.474 37.614 59.9 c -2.822 -1.863 -4.473 -4.715 -4.65 -8.03 c -0.348 -6.535 4.836 -11.238 12.9 -11.703 c 2.863 -0.164 5.541 -0.035 8.017 0.386 c -0.329 -2.011 -0.993 -3.604 -1.985 -4.756 c -1.364 -1.583 -3.471 -2.396 -6.264 -2.413 c -0.026 0 -0.052 0 -0.078 0 c -2.242 0 -5.287 0.628 -7.227 3.571 l -4.665 -3.2 c 2.597 -3.941 6.816 -6.106 11.89 -6.106 c 0.038 0 0.077 0 0.115 0 c 8.482 0.054 13.533 5.347 14.035 14.591 c 0.288 0.124 0.573 0.253 0.852 0.387 c 3.958 1.898 6.852 4.773 8.37 8.312 c 2.116 4.935 2.309 12.975 -4.111 19.388 C 59.907 75.229 53.95 77.441 45.5 77.5 h -0.038 c -9.505 -0.066 -16.814 -3.264 -21.724 -9.506 c -4.369 -5.554 -6.623 -13.283 -6.699 -22.971 l 0 -0.023 l 0 -0.023 c 0.076 -9.688 2.33 -17.417 6.699 -22.971 c 4.91 -6.242 12.219 -9.44 21.724 -9.506 H 45.5 c 9.524 0.066 16.916 3.252 21.97 9.47 c 2.494 3.068 4.326 6.767 5.491 11.06 l -5.466 1.458 c -0.961 -3.487 -2.418 -6.479 -4.351 -8.857 c -3.942 -4.85 -9.884 -7.339 -17.662 -7.396 c -7.722 0.057 -13.562 2.534 -17.359 7.361 c -3.556 4.52 -5.393 11.048 -5.462 19.404 C 22.729 53.356 24.567 59.884 28.122 64.404 z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: 'currentColor',
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 46.181 45.893 c -5.811 0.335 -7.738 3.146 -7.604 5.666 c 0.181 3.398 3.853 4.984 7.387 4.785 c 3.46 -0.19 7.388 -1.548 8.058 -9.91 c -1.795 -0.392 -3.77 -0.598 -5.881 -0.598 C 47.5 45.836 46.847 45.854 46.181 45.893 z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: 'rgb(0,0,0)',
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}

export default ThreadsIcon;
