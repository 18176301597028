import { useTranslation } from 'next-i18next';
import { referralRUF } from '../functions/common';

function useH5StringBuilder() {
  if (typeof window === 'undefined') return { phrase: 'https://m.bitop.com', referral: '' };

  const { i18n } = useTranslation('common');
  // e.g. https://www.bitop.com/en?ru=631915146406936576&f=BTITart
  const hostname = window.location.hostname.split('.').slice(-2).join('.');
  const phrase = `${window.location.protocol}//m.${hostname}${i18n.language === 'en' ? '/eng' : '/zh'}`;
  const RUF = referralRUF();

  return {
    phrase,
    referral: RUF && RUF,
  };
}

export default useH5StringBuilder;
