function LiIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title />
      <g id="Icons-/-Systems-/-Media-/-Linkedin-Hover" stroke="none" strokeWidth="1" fillRule="evenodd">
        <path fill="currentColor" d="M16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 Z M11.7708296,12.3811901 L7.94752041,12.3811901 L7.94752041,23.8839174 L11.7708296,23.8839174 L11.7708296,12.3811901 Z M21.1572605,12.1110913 C19.1251025,12.1110913 18.2186887,13.2291347 17.7104353,14.0111947 L17.7104353,14.0111947 L17.7104353,12.3811901 L13.8854148,12.3811901 C13.9006263,12.7008846 13.9068236,13.8409471 13.9080129,15.2871671 L13.9080286,16.4212021 C13.9079621,16.5191497 13.9078799,16.617791 13.9077831,16.7170005 L13.9070338,17.3180722 C13.9027889,20.1455206 13.8885751,23.2216148 13.8858638,23.7905081 L13.8854148,23.8836322 L17.7101501,23.8836322 L17.7101501,17.459446 C17.7101501,17.1146208 17.7361047,16.7732183 17.8367856,16.5273628 C18.1140148,15.8394239 18.7417733,15.1289529 19.7984955,15.1289529 C21.1832151,15.1289529 21.7353916,16.184249 21.7353916,17.7295447 L21.7353916,17.7295447 L21.7353916,23.8839174 L25.5598417,23.8839174 L25.5598417,17.2874612 C25.5598417,13.7550715 23.6734286,12.1110913 21.1572605,12.1110913 Z M9.88441656,6.83660737 C8.57613462,6.83660737 7.72020393,7.69539021 7.72020393,8.82370138 C7.72020393,9.92805448 8.5513209,10.811651 9.83421869,10.811651 L9.85903241,10.811651 C11.1929837,10.811651 12.0223894,9.92805448 12.0223894,8.82370138 C11.9970052,7.69539021 11.1929837,6.83660737 9.88441656,6.83660737 Z" id="Combined-Shape" fillRule="nonzero" />
      </g>
    </svg>
  );
}

export default LiIcon;
