import Image from 'next/image';
import CoinMarketCapLogo from '@/components/pages/home/imgs/coinMarketCapLogo.png';
import BanxaLogo from '@/components/pages/home/imgs/banxaLogo.png';
import TradingViewLogo from '@/components/pages/home/imgs/tradingViewLogo.png';
import CoinGeckoLogo from '@/components/pages/home/imgs/coinGeckoLogo.png';
import YahooLogo from '@/components/pages/home/imgs/yahooLogo.png';
import styles from './LogoMarquee.module.scss';

function RotatingPart() {
  return (
    <>
      <Image alt="CoinMarketCapLogo" src={CoinMarketCapLogo} objectFit="contain" />
      <Image alt="BanxaLogo" src={BanxaLogo} objectFit="contain" />
      <Image alt="TradingViewLogo" src={TradingViewLogo} objectFit="contain" />
      <Image alt="CoinGeckoLogo" src={CoinGeckoLogo} objectFit="contain" />
      <Image alt="YahooLogo" src={YahooLogo} objectFit="contain" />
    </>
  );
}

function LogoMarquee() {
  return (
    <div className={styles.marqueeCon}>
      <div className={styles.marquee}>
        <div className={styles.rotateMe}>
          <RotatingPart />
          <RotatingPart />
          <RotatingPart />
          <RotatingPart />
        </div>
      </div>

    </div>
  );
}

export default LogoMarquee;
