function OldVersionIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 18 18">
      <polyline fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" points="8.28 11.79 11.27 9 8.28 6.21" />
      <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M9,.53c4.68,0,8.47,3.79,8.47,8.47s-3.79,8.47-8.47,8.47S.53,13.68.53,9h10.03" />
    </svg>
  );
}

export default OldVersionIcon;
