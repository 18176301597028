function UnAuthIcon() {
  return (
    <svg
      width="10px"
      height="10px"
      viewBox="0 0 10 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Icons-/-Systems-/-User-/-Unverified"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <polygon
          id="星形"
          fill="#777E90"
          points="5 9.04622396 3.45491503 9.75528258 2.62168923 8.27346395 0.954915028 7.93892626 1.15181234 6.25035197 0 5 1.15181234 3.74964803 0.954915028 2.06107374 2.62168923 1.72653605 3.45491503 0.244717419 5 0.953776042 6.54508497 0.244717419 7.37831077 1.72653605 9.04508497 2.06107374 8.84818766 3.74964803 10 5 8.84818766 6.25035197 9.04508497 7.93892626 7.37831077 8.27346395 6.54508497 9.75528258"
        />
        <path
          d="M5,6.66666667 C5.3681898,6.66666667 5.66666667,6.96514353 5.66666667,7.33333333 C5.66666667,7.70152313 5.3681898,8 5,8 C4.63181017,8 4.33333333,7.70152313 4.33333333,7.33333333 C4.33333333,6.96514353 4.63181017,6.66666667 5,6.66666667 Z M5,2 C5.3681898,2 5.66666667,2.23451751 5.66666667,2.52380952 L5.66666667,5.14285714 C5.66666667,5.43214913 5.3681898,5.66666667 5,5.66666667 C4.63181017,5.66666667 4.33333333,5.43214913 4.33333333,5.14285714 L4.33333333,2.52380952 C4.33333333,2.23451751 4.63181017,2 5,2 Z"
          id="Combined-Shape"
          fill="#28282B"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default UnAuthIcon;
