function LtIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title />
      <g id="Icons-/-Systems-/-Media-/-Linktree-Hover" stroke="none" strokeWidth="1" fillRule="evenodd">
        <path fill="currentColor" d="M16 0C7.165 0 0 7.162 0 16c0 8.835 7.165 16 16 16s16 -7.165 16 -16S24.835 0 16 0z" />
        <path d="M14.688 19.13h2.611V25.024H14.688V19.13zM8.653 13.088h4.442l-3.158 -3.011 1.747 -1.795 3.011 3.094V6.976h2.611v4.4l3.011 -3.088 1.747 1.789 -3.155 3.005h4.442v2.483H18.88l3.178 3.088 -1.741 1.754L16 16.07 11.686 20.406l-1.747 -1.747 3.178 -3.088H8.653V13.088z" id="Path" fillRule="nonzero" />
      </g>
    </svg>
  );
}

export default LtIcon;
