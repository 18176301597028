function BitopIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="65.295" height="33.295" viewBox="0 0 65.295 33.295">
      <defs>
        <linearGradient id="linearbitop" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#00a5ff" />
          <stop offset="0.3" stopColor="#00cbfd" />
          <stop offset="1" stopColor="#00f2ed" />
        </linearGradient>
      </defs>
      <g id="Group_2" data-name="Group 2" transform="translate(0.5 0.5)">
        <path id="Union_45" data-name="Union 45" d="M48.893,32.3V16.1a7.657,7.657,0,0,1,.6-3,7.67,7.67,0,0,1,4.1-4.1,7.758,7.758,0,0,1,5.992,0,7.668,7.668,0,0,1,4.1,4.1,7.767,7.767,0,0,1,0,5.992,7.671,7.671,0,0,1-4.1,4.1,7.787,7.787,0,0,1-5.6.157,7.743,7.743,0,0,1-2.214-1.24V32.3Zm5.824-20.631a4.887,4.887,0,0,0-2.563,2.563,4.913,4.913,0,0,0,0,3.753,4.881,4.881,0,0,0,2.563,2.563A4.8,4.8,0,0,0,60,19.509a5.067,5.067,0,0,0,1.034-1.529,4.913,4.913,0,0,0,0-3.753A5.057,5.057,0,0,0,60,12.7a4.853,4.853,0,0,0-5.283-1.035ZM36.3,23.2a7.672,7.672,0,0,1-4.1-4.1,7.767,7.767,0,0,1,0-5.992,7.67,7.67,0,0,1,4.1-4.1,7.758,7.758,0,0,1,5.992,0,7.67,7.67,0,0,1,4.1,4.1,7.767,7.767,0,0,1,0,5.992,7.672,7.672,0,0,1-4.1,4.1,7.759,7.759,0,0,1-5.992,0Zm1.119-11.538a4.844,4.844,0,0,0-2.562,6.317,5.039,5.039,0,0,0,1.034,1.528,4.861,4.861,0,0,0,1.528,1.034,4.807,4.807,0,0,0,1.877.373,4.865,4.865,0,0,0,1.877-.373A5.042,5.042,0,0,0,42.7,19.509a4.882,4.882,0,0,0,1.023-1.528A4.834,4.834,0,0,0,42.7,12.7a5.058,5.058,0,0,0-1.528-1.035,4.866,4.866,0,0,0-1.877-.373A4.808,4.808,0,0,0,37.414,11.664ZM4.7,23.2A7.672,7.672,0,0,1,.6,19.1a7.658,7.658,0,0,1-.6-3V0H2.888V10.088A7.731,7.731,0,0,1,10.7,9.005a7.663,7.663,0,0,1,4.1,4.1,7.759,7.759,0,0,1,0,5.992,7.666,7.666,0,0,1-4.1,4.1,7.759,7.759,0,0,1-5.992,0ZM5.823,11.664A4.863,4.863,0,0,0,4.3,12.7a5.044,5.044,0,0,0-1.034,1.528,4.915,4.915,0,0,0,0,3.754A5.061,5.061,0,0,0,4.3,19.509a4.851,4.851,0,0,0,5.283,1.034A5.035,5.035,0,0,0,11.1,19.509a4.879,4.879,0,0,0,1.023-1.528,4.775,4.775,0,0,0,0-3.754A4.863,4.863,0,0,0,11.1,12.7a5.05,5.05,0,0,0-1.527-1.035,4.917,4.917,0,0,0-3.755,0ZM25.392,23.323V14.949l-1.167.674-1.443-2.5,2.611-1.5V6H28.28V9.944l1.167-.674,1.443,2.5-2.611,1.5V23.323Zm-7.489,0V9.848H20.79V23.323Zm.252-16.135a1.684,1.684,0,0,1,0-2.382A1.685,1.685,0,0,1,21.031,6a1.685,1.685,0,0,1-2.876,1.191Z" transform="translate(0 0)" stroke="rgba(0,0,0,0)" strokeWidth="1" fill="url(#linearbitop)" />
      </g>
    </svg>
  );
}

export default BitopIcon;
