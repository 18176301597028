function MoreIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="6" viewBox="0 0 18 5">
      <g id="BytesizeEllipsisHorizontal" transform="translate(-4.5 -13.5)">
        <circle id="Ellipse_524" data-name="Ellipse 524" cx="2" cy="2" r="2" transform="translate(5 14)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <circle id="Ellipse_525" data-name="Ellipse 525" cx="2" cy="2" r="2" transform="translate(11.5 14)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        <circle id="Ellipse_526" data-name="Ellipse 526" cx="2" cy="2" r="2" transform="translate(18 14)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
      </g>
    </svg>
  );
}

export default MoreIcon;
