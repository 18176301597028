function MarketsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 16.188">
      <g id="Group_5253" data-name="Group 5253" transform="translate(872.742 -22293.57)">
        <g id="Group_5252" data-name="Group 5252" transform="translate(-872.242 22294.07)">
          <path id="Path_3811" data-name="Path 3811" d="M-866.242,22321.865h17" transform="translate(866.242 -22306.678)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1" />
          <path id="Path_3812" data-name="Path 3812" d="M-866.242,22197.361h6.108V22185.1h-6.108Z" transform="translate(867.393 -22185.104)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1" />
          <path id="Path_3813" data-name="Path 3813" d="M-866.242,22193.615h6.108v-8.512h-6.108Z" transform="translate(875.983 -22181.357)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1" />
        </g>
      </g>
    </svg>
  );
}

export default MarketsIcon;
