function TgIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title />
      <g id="Icons-/-Systems-/-Media-/-Telegram-Hover" stroke="none" strokeWidth="1" fillRule="evenodd">
        <path fill="currentColor" d="M16,0.001 C24.836556,0.001 32,7.164444 32,16.001 C32,24.837556 24.836556,32.001 16,32.001 C7.163444,32.001 0,24.837556 0,16.001 C0,7.164444 7.163444,0.001 16,0.001 Z M23.8573333,10.9636667 C24.0893333,9.881 23.464,9.44766667 22.748,9.70633333 L22.748,9.70633333 L7.32133333,15.6543333 C6.264,16.0676667 6.28266667,16.653 7.14,16.925 L7.14,16.925 L11.0893333,18.157 L20.2506667,12.389 C20.676,12.1063333 21.0706667,12.2663333 20.748,12.5503333 L20.748,12.5503333 L13.3346667,19.2476667 L13.0506667,23.3183333 C13.464,23.3183333 13.644,23.1383333 13.8573333,22.925 L13.8573333,22.925 L15.7866667,21.0663333 L19.7866667,24.0143333 C20.516,24.4276667 21.0373333,24.2143333 21.232,23.337 L21.232,23.337 L23.8586667,10.9623333 Z" id="Combined-Shape" fillRule="nonzero" />
      </g>
    </svg>
  );
}

export default TgIcon;
