import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './Portal.module.scss';

function Wrap({
  children,
  onClick,
  footer,
  className = '',
  opacity = 0.5,
}) {
  const handleClick = useCallback(({ currentTarget, target }) => {
    const { childNodes } = currentTarget;
    for (let i = 0; i < childNodes.length; i += 1) {
      if (childNodes[i] === target) {
        onClick();
      }
    }
  }, [onClick]);

  return (
    <div className={styles.overlay}>
      <div onClick={handleClick} aria-hidden="true" className={styles.modal} style={{ backgroundColor: `rgba(0,0,0, ${opacity})` }}>
        <div className={`${styles.content} ${className}`}>
          {children}
        </div>
        {
          footer && <footer className={styles.footer}>{footer}</footer>
        }
      </div>
    </div>
  );
}

function usePortal({
  closeOnOutSide = null,
  className = '',
  defaultShow = false,
  defaultFailShow = false,
  opacity = 0.5,
} = {}) {
  const [isShow, setShow] = useState(defaultShow);
  const [isFailShow, setFailShow] = useState(defaultFailShow);

  const PortalClose = useCallback(() => {
    document.body.classList.remove(styles.noScroll);
    setShow(false);
  }, []);

  const PortalFailClose = useCallback(() => {
    document.body.classList.remove(styles.noScroll);
    setFailShow(false);
  }, []);

  const PortalOpen = useCallback(() => {
    document.body.classList.add(styles.noScroll);
    setShow(true);
  }, []);

  const PortalFailOpen = useCallback(() => {
    document.body.classList.add(styles.noScroll);
    setFailShow(true);
  }, []);

  const Portal = useCallback(({ children, footer }) => {
    if (!isShow) return null;

    return (
      <Wrap onClick={closeOnOutSide ? PortalClose : PortalOpen} className={className} footer={footer} opacity={opacity}>
        {children}
      </Wrap>
    );
  }, [isShow, className, closeOnOutSide]);

  const PortalFail = useCallback(({ children, footer }) => {
    if (!isFailShow) return null;

    return (
      <Wrap onClick={closeOnOutSide ? PortalFailClose : PortalFailOpen} className={className} footer={footer} opacity={opacity}>
        {children}
      </Wrap>
    );
  }, [isFailShow, className, closeOnOutSide]);

  return {
    Portal,
    isShow,
    PortalClose,
    PortalOpen,
    PortalFail,
    isFailShow,
    PortalFailClose,
    PortalFailOpen,
  };
}

Wrap.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  footer: PropTypes.node,
  className: PropTypes.node,
  opacity: PropTypes.number,
};

Wrap.defaultProps = {
  children: '',
  onClick: () => {},
  footer: '',
  className: '',
  opacity: 0.5,
};

export default usePortal;
