function LogoutIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 18 18">
      <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M5.76,17.5H1.29c-.42,0-.76-.34-.76-.76V1.26c0-.42.34-.76.76-.76h4.47" />
      <g>
        <line fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" x1="4.81" y1="9" x2="17.48" y2="9" />
        <polyline fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" points="13.29 13.54 17.48 9 13.29 4.46" />
      </g>
    </svg>
  );
}

export default LogoutIcon;
