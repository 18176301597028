import reRouteTo from '@/utils/api/sessionPass';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import styles from './ServicePlatformChoice.module.scss';

function ServicePlatformChoice({
  className, width, cross, height, hideTitle, closeFunc,
}) {
  const { t, i18n } = useTranslation('pages', { keyPrefix: 'servicePlatformChoice' });
  function handleRedirect(e) {
    const { dataset } = e.currentTarget;
    closeFunc();
    reRouteTo(`${dataset.path}#download`);
  }

  return (
    <div className={`${styles.container} ${className || ''}`} style={{ minWidth: width || '100%', maxHeight: height || '100%' }}>
      {cross && <div className={styles.cross} onKeyDown={closeFunc} onClick={closeFunc} role="presentation">X</div>}
      <div className={styles.title}>{hideTitle ? '' : t('header') }</div>
      <div className={styles.content}>{t('bodyContent')}</div>
      <div style={{ display: hideTitle ? 'flex' : 'initial', justifyContent: hideTitle ? 'space-evenly' : 'initial' }}>
        <div
          className={`${styles.btn}`}
          onClick={handleRedirect}
          onKeyDown={handleRedirect}
          data-path={i18n.language === 'en' ? '/en' : '/'}
          role="presentation"
        >
          {t('choice1')}
        </div>

        <div
          className={`${styles.btn} ${styles.btnFade}`}
          onKeyDown={handleRedirect}
          onClick={handleRedirect}
          data-path="/login"
          role="presentation"
        >
          {t('choice2')}
        </div>
      </div>

    </div>
  );
}

ServicePlatformChoice.propTypes = {
  className: PropTypes.node,
  cross: PropTypes.bool,
  closeFunc: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  hideTitle: PropTypes.bool,
};

ServicePlatformChoice.defaultProps = {
  className: '',
  cross: false,
  closeFunc: () => {},
  width: '100%',
  height: '100%',
  hideTitle: false,
};

export default ServicePlatformChoice;
