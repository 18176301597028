function TradeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16.958 16.645">
      <g id="Group_4837" data-name="Group 4837" transform="translate(13651.72 -10793)">
        <path id="Path_2384" data-name="Path 2384" d="M-13631.5,10808.734v-2.355h-3.263v-11.755h3.263v0h2.736v11.755h-2.736Z" transform="translate(-6.498 0.91)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1" />
        <path id="Path_2385" data-name="Path 2385" d="M-13631.155,10808.374v-2.735h-3.147v-9.052h3.147v0h2.853v9.052h-2.853Z" transform="translate(-16.917 1.271)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1" />
      </g>
    </svg>
  );
}

export default TradeIcon;
