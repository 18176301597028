function Hamburger() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18.736 10.693">
      <g id="Group_4719" data-name="Group 4719" transform="translate(-318.225 -35.514)">
        <path id="Path_2267" data-name="Path 2267" d="M17434.223,16042.859h18.736" transform="translate(-17115.998 -16006.846)" fill="none" stroke="currentColor" strokeWidth="1" />
        <path id="Path_2268" data-name="Path 2268" d="M17434.223,16042.859h18.736" transform="translate(-17115.998 -15997.152)" fill="none" stroke="currentColor" strokeWidth="1" />
        <path id="Path_2269" data-name="Path 2269" d="M17434.223,16042.859h18.736" transform="translate(-17115.998 -16002)" fill="none" stroke="currentColor" strokeWidth="1" />
      </g>
    </svg>
  );
}

export default Hamburger;
